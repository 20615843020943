<template>
  <section>
    <div class="row">
      <div
        class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3"
      >
        <div class="table-box p-3">
          <div
            class="table-title d-flex flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column mb-3"
          >
            <div class="flex-grow-1 my-auto">
              <h2
                class="mb-xxl-0 mb-xl-0 mb-lg-0 mb-md-0 mb-sm-3 mb-3 border-bottom pb-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-tag-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
                  />
                </svg>
                {{ dataList.formData.identifier }}
              </h2>
            </div>
          </div>
          <div class="new-template-form">
            <form role="form" class="row" @submit.prevent="submitForm">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <label class="form-control-label">{{
                  translations?.default_content
                }}</label>
                <editor
                  :api-key="editorKey"
                  :disabled="disabled"
                  id="editor_def_content"
                  :init="{
                    element_format: 'html',
                    pthManager: siteUrl(
                      `tinymce?company_id=${selectedCompany.id}`
                    ),
                    menubar: 'insert',
                    paste_as_text: true,
                    branding: false,
                    convert_urls: false,
                    relative_urls: false,
                    image_caption: true,
                    image_advtab: true,
                    height: 285,
                    valid_elements: '+*[*]',
                    pagebreak_separator:
                      '<br style=\'page-break-before:always\'/>',
                    nonbreaking_force_tab: true,
                    content_css: [
                      '/css/paper/paper.min.css?v=' + Math.random(),
                    ],
                    body_id: 'wrapper',
                    plugins: [
                      'autoresize advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen pagebreak',
                      'insertdatetime media table paste code codesample help wordcount preview hr',
                    ],
                    external_plugins: {
                      autocomplete: '/tinymce/plugins/autocomplete/plugin.js',
                      WYImageManager:
                        '/tinymce/plugins/wy_image_manager/plugin.js',
                      glossary: '/tinymce/plugins/glossary/plugin.js',
                    },
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \ alignleft aligncenter alignright alignjustify | WYImageManager | \ bullist numlist outdent indent | link s | table | glossary | removeformat | pagebreak | help',
                  }"
                  v-model="dataList.formData.default_content"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <label class="form-control-label">{{
                  translations?.explanation
                }}</label>
                <editor
                  :api-key="editorKey"
                  :disabled="disabled"
                  id="editor_exp"
                  :init="{
                    pthManager: siteUrl(
                      `tinymce?company_id=${selectedCompany.id}`
                    ),
                    paste_as_text: true,
                    height: 285,
                    menubar: false,
                    body_id: 'wrapper',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | link s | table | removeformat',
                  }"
                  v-model="dataList.formData.explanation"
                />
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <label class="form-control-label">{{
                  translations?.links
                }}</label>
                <editor
                  :api-key="editorKey"
                  :disabled="disabled"
                  id="editor_link"
                  :init="{
                    pthManager: siteUrl(
                      `tinymce?company_id=${selectedCompany.id}`
                    ),
                    paste_as_text: true,
                    height: 285,
                    menubar: false,
                    body_id: 'wrapper',
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'formatselect | bold italic backcolor | \
                                                            alignleft aligncenter alignright alignjustify | \
                                                            bullist numlist outdent indent | link s | table | removeformat',
                  }"
                  v-model="dataList.formData.links"
                />
              </div>
              <div class="form-group row m-t-md">
                <div class="col-sm-offset-2 col-sm-10">
                  <button
                    type="submit"
                    :disabled="!data.permission.canEdit"
                    class="btn btn-green me-2"
                  >
                    {{ translations?.save }}
                  </button>
                  <router-link
                    :to="$route.meta.backURL"
                    class="btn btn-secondary"
                  >
                    {{ translations?.cancel }}
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import HelperFunction from "@/common/helpers";
import { useRouter } from "vue-router";

export default {
  name: "CreateContentLibrary",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    let store = useStore();
    const router = useRouter();
    const { showToast, data, formPermissionResolver } = HelperFunction();
    const editorKey = process.env.VUE_APP_EDITOR_KEY;

    formPermissionResolver("/library/content-library");

    let dataList = reactive({
      formData: {
        id: "",
        identifier: "",
        default_content: "",
        explanation: "",
        links: "",
      },
    });
    let init = {
      height: 200,
      menubar: false,
      plugins: [
        "advlist autolink lists link image charmap print preview anchor",
        "searchreplace visualblocks code fullscreen",
        "insertdatetime media table paste code help wordcount",
      ],
      toolbar:
        "undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | help",
    };

    const selectedCompany = computed(() => store.getters.getSelectedBoard);

    const translations = computed(() => {
      return store.state.translations;
    });

    /** watch **/
    watch(
      () => props.id,
      (value) => {
        if (value) {
          store
            .dispatch("getContentItem", value)
            .then((resp) => {
              dataList.formData = { ...dataList.formData, ...resp.data.data };
              //showToast(resp);
            })
            .catch((error) => {
              showToast(error);
            });
        }
      },
      { immediate: true }
    );
    /** watch end**/

    const submitForm = () => {
      if (!data.permission.canEdit) return false;
      let payload = {
        action: dataList.formData.id,
        formData: {
          identifier: dataList.formData.identifier,
          default_content: dataList.formData.default_content,
          explanation: dataList.formData.explanation,
          links: dataList.formData.links,
        },
      };
      store
        .dispatch("updateContentItem", payload)
        .then((resp) => {
          showToast(resp);
          router.go(-1);
        })
        .catch((err) => {
          showToast(err);
        });
    };
    const updateLocalStroage = () => {
      console.log("Update local storage with default content");
    };
    const siteUrl = (url) => {
      return process.env.VUE_APP_BACKEND_URL + url;
    };
    return {
      data,
      editorKey,
      init,
      dataList,
      submitForm,
      selectedCompany,
      updateLocalStroage,
      siteUrl,
      translations,
    };
  },
};
</script>

<style scoped></style>
